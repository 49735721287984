/* ========================================================================
 * Script to manage dates & calendar in single event pages.
 * ======================================================================== */

(function($) {
   var eventDates = [];
   var defaultDate = null;
   if ($('body.single-ahf_event').length > 0) {
      if (php_vars) {
         $.each(php_vars, function(i,v){
            eventDates[new Date(v)] = new Date(v);
            if (i == 0) {
               defaultDate = new Date(v);
            }
         })
         $('#jq-cal').datepicker({
            beforeShowDay: function(date) {
               var highlight = eventDates[date];
               if (highlight) {
                  return [true, 'event-on',highlight];
               } else {
                  return [true, '', ''];
               }
            },
            defaultDate: defaultDate,
            firstDay: 1,
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
         });
      }
   }
   
})(jQuery);
